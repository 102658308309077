import React, { useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import Navbar from './components/Navbar/Navbar'
import Home from './components/Home/Home'
import Login from './components/Login/Login'
import SignUp from './components/SignUp/SignUp'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import Courses from './components/Courses/Courses'
import Events from './components/Events/Events'
import Blog from './components/Blog/Blog';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import VerifyEmail from './components/VerifyEmail/VerifyEmail';
import AuthGuard from './helpersComponents/AuthGuard';

import { Provider } from 'react-redux'
import store, { persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
// import SingleCourse from './components/SingleCourse/SingleCourse';
import NewSingleCourse from './components/SingleCourse/NewSingleCourse/NewSingleCourse';
import SingleBlog from './components/SingleBlog/SingleBlog'
import SingleEvent from './components/SingleEvent/SingleEvent';
import AboutUs from './components/AboutUs/AboutUs';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import RefundPolicy from './components/RefundPolicy/RefundPolicy';
import ErrorPage from './components/ErrorPage/ErrorPage';
import SelfPaced from './components/SelfPaced/SelfPaced';
import SingleSelfPacedCourse from './components/SingleSelfPacedCourse/SingleSelfPacedCourse';
import UserState from './userContext/UserState';
import VerifyCertificate from './components/VerifyCertificate/VerifyCertificate'
import RamNewSingleCourse from './components/SingleCourse/RamNewSingleCourse/RamNewSingleCourse';

let goToTopIcon = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/goToTop.svg'
let whatsAppIcon = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/whatsAppIcon.svg'
function App() {
  return (
    <AnimatePresence>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <UserState>
            <div className='tw-overflow-x-hidden'>
              {/* <span className='container' 
              style={{color: "red", marginTop: "90vh", position: "fixed", zIndex: 1000}} 
              onClick={()=>window.scrollTo({top:0, left:0, behavior:"instant"})}>
              <button className='tw-btn tw-btn-outline tw-btn-circle tw-bg-black tw-border-white'><img src={goToTop} alt="goto top icon" width={22}/></button>
            </span> */}
              <span className='container tw-flex tw-flex-col tw-w-min'
                style={{ color: "red", marginTop: "80vh", right: 0, position: "fixed", zIndex: 1000, opacity: 0.7, top: 0 }}
                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "instant" })}
              >
                {/* whatsapp://send?phone= +91 80935 55001&text=Hi, I want to join your certification program. Please help me out. */}
                <a className='tw-mb-3' href="//wa.me/+918093555001" target='__blank' rel="noopener noreferrer"><img src={whatsAppIcon} alt="whatsapp icon" className='tw-w-11' /></a>
                <button className='tw-btn tw-btn-outline tw-btn-circle tw-bg-[#999999] tw-border-[#999999]'><img src={goToTopIcon} alt="goto top icon" className='tw-w-6' /></button>
              </span>
              <BrowserRouter>
                <ScrollToTop />
                <Navbar />
                {/* <BottomNavbar /> */}
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/verifyEmail" element={<VerifyEmail />} />
                  <Route path="/course" element={<Courses />} />
                  <Route path="/self-paced-course" element={<SelfPaced />} />
                  <Route path="/event" element={<Events />} />
                  <Route path="/blogs" element={<Blog />} />
                  <Route path="/about" element={<AboutUs />} />
                  <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/refund-policy" element={<RefundPolicy />} />

                  {/**RAM's course launch url */}
                  <Route path="/cepm" element={<RamNewSingleCourse />} />

                  {/* protected routes */}
                  {/* <Route path="/course/:id" element={<AuthGuard message="Login to get the exclusive access to our training programs"> <NewSingleCourse/> </AuthGuard>} />
                <Route path="/blogs/:id" element={<AuthGuard message="Unlock event access through a secure login"> <SingleBlog/> </AuthGuard>} /> */}

                  <Route path="/course/:id" element={<NewSingleCourse />} />
                  <Route path="/blogs/:id" element={<SingleBlog />} />

                  <Route path="/event/:id" element={<SingleEvent />} />
                  {/* <Route path="/self-paced-course/:id" element={<SingleSelfPacedCourse />} /> */}
                  <Route path='/verify-certificate' element={<VerifyCertificate /> } />

                  <Route path="*" element={<ErrorPage />} />
                </Routes>

                <Footer />
              </BrowserRouter>
            </div>
          </UserState>
        </PersistGate>
      </Provider>
    </AnimatePresence>
  )
}

export default App

function BottomNavbar() {
  // console.log('object');
  return (
    <div style={{ position: 'fixed', bottom: 0, backgroundColor: 'red', width: '100vw', height: 64, zIndex: 110, padding: '8px 0px 8px 0px' }}>
      hi
    </div>
  )
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [pathname]);

  return null;
}