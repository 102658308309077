import React, { useState, useLayoutEffect, useCallback, useContext, useEffect } from 'react'
import './Navbar.css'
import { motion } from 'framer-motion'
import { Link, NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {logout} from '../../redux/login/loginActions'
import OfferHeader from './OfferHeader'
import { useLocation } from 'react-router-dom';
import {UserContext} from '../../userContext/UserState'
import NewCourseButton from '../SingleCourse/NewSingleCourse/common/NewCourseButton'
import { RequestACallback } from '../SingleCourse/NewSingleCourse/NewSingleCourse'


let logo = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/finocontrol_logo_main.svg';
let moboLogo = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/finocontrol_logo_main_mobo.svg'
let searchIcon = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/search.svg'
let basketIcon = 'https://new-finocontrol-website-bucket.s3.ap-south-1.amazonaws.com/finocontrol-website-assets/home-page-other-images/basket.svg'

function Navbar() {
  const loginState= useSelector(state=> state.login);
  const dispatch= useDispatch();

  const [menu, setMenu] = useState(false);
  const showHide = (e) => {
    setMenu(x => !x)
  }
  const menuVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "110%" },
  }


  const [isMobo, setIsMobo]=useState(false);
  useLayoutEffect(()=>{
    if(window.screen.width <= 992) {
      setIsMobo(true);
    } else {
      setIsMobo(false)
    }
  }, [])

  const [showTalkToClient, setShowTalkToClient] = useState(false);
  const {userState, setUserState} = useContext(UserContext);
  const location = useLocation();
  const [extraofferHeader, setExtraofferHeader]=useState(false)
  useEffect(()=>{
    const arr= userState?.offerHeaderOnlyFor.map(loc=>location.pathname.startsWith(loc)?loc:null);
    if(arr.filter(item=>item).length>0) {
      return setExtraofferHeader(true);
    }
    return setExtraofferHeader(false);
  }, [location, userState?.offerHeaderOnlyFor])

  useEffect(()=>{
    if(menu) {
      document.body.style.overflowY="hidden"
    } else {
      document.body.style.overflowY="auto"
    }
  }, [menu])

  return (
    <nav className='fixed-top tw-bg-[#140342]' >
      <RequestACallback />
      {extraofferHeader && <OfferHeader offerDate={new Date("2023-10-16")}/>}
      <div className='tw-navbar container navbar_bottom_effect tw-h-[0px]'>
        <div className="col">
          <Link to="/">
            <span><img className="img-fluid tw-w-36 lg:tw-w-40" src={isMobo? moboLogo :logo} alt="fin logo" /></span>
          </Link>
        </div>
        <div className="tw-hidden lg:tw-flex text-white">
          <NavLink style={({ isActive, isPending }) => {
            return {
              color: isActive ? "rgba(193, 44, 180, 1)" : "",
            };
          }} to="/" className=' tw-btn tw-btn-ghost tw-capitalize'>Home</NavLink >
          <NavLink style={({ isActive, isPending }) => {
            return {
              color: isActive ? "rgba(193, 44, 180, 1)" : "",
            };
          }} to='/course' className=' tw-btn tw-btn-ghost tw-capitalize' >Corporate Trainings</NavLink>
          <NavLink style={({ isActive, isPending }) => {
            return {
              color: isActive ? "rgba(193, 44, 180, 1)" : "",
            };
          }} to="/verify-certificate" className=' tw-btn tw-btn-ghost tw-capitalize'>Verify Certificate</NavLink > 
          <NavLink style={({ isActive, isPending }) => {
            return {
              color: isActive ? "rgba(193, 44, 180, 1)" : "",
            };
          }} to="/event" className=' tw-btn tw-btn-ghost tw-capitalize'>Events</NavLink >
          <NavLink style={({ isActive, isPending }) => {
            return {
              color: isActive ? "rgba(193, 44, 180, 1)" : "",
            };
          }} to="/blogs" className=' tw-btn tw-btn-ghost tw-capitalize'>Blogs</NavLink >
          <NavLink style={({ isActive, isPending }) => {
            return {
              color: isActive ? "rgba(193, 44, 180, 1)" : "",
            };
          }} to="/about" className=' tw-btn tw-btn-ghost tw-capitalize'>About Us</NavLink >
          <NavLink style={({ isActive, isPending }) => {
            return {
              color: isActive ? "rgba(193, 44, 180, 1)" : "",
            };
          }} to="/contact" className=' tw-btn tw-btn-ghost tw-capitalize'>Contact</NavLink >
        </div>
        <div className="tw-hidden lg:tw-flex col tw-justify-end">
        <div className='tw-flex'>
          <div className="tw-text-sm tw-dropdown tw-dropdown-hover">
            <label tabIndex={0} className="tw-btn tw-btn-md tw-text-black tw-border tw-border-[#EBBA0D] tw-bg-[#EBBA0D] tw-capitalize hover:tw-bg-[#EBBA0D] hover:tw-border-[#EBBA0D] tw-rounded-xl tw-w-full tw-font-medium">
              Talk To our Experts
            </label>
            <div tabIndex={0} className="tw-dropdown-content !tw-z-[101] tw-menu tw-rounded-box tw-pt-4  ">
              <TalkToExpert />
            </div>
          </div>
        </div>
          {/* {
            loginState.isLoggedIn && <div> 
                <span className='tw-btn tw-btn-ghost tw-btn-sm tw-capitalize tw-bg-white hover:tw-bg-[black] hover:tw-text-white btn-xs sm:btn-sm md:btn-md lg:btn-lg' onClick={()=> dispatch(logout())} >Logout</span>
              </div>
          }
          {
            !loginState.isLoggedIn && <span>
              <span><Link to="/login" className='tw-btn tw-btn-ghost text-white tw-capitalize'>Login</Link></span>
              <span><Link to="/signup" className='tw-btn tw-btn-ghost tw-btn-sm tw-capitalize tw-bg-white hover:tw-bg-[black] hover:tw-text-white btn-xs sm:btn-sm md:btn-md lg:btn-lg'>Sign up</Link></span>
            </span>           
          } */}
        </div>

        {/* mobile view */}
        <button className="tw-flex lg:tw-hidden tw-dropdown tw-dropdown-bottom tw-dropdown-end" >

          <label className="tw-btn tw-m-1 tw-btn-ghost tw-btn-circle tw-swap tw-swap-rotate"  >
            <input type="checkbox" onClick={showHide} />
            {!menu && <svg style={{ transition: 'all 0.2s' }} width="26" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="25" height="2" fill="white" />
              <rect y="11" width="25" height="2" fill="white" />
              <rect y="22.7273" width="25" height="2" fill="white" />
            </svg>}
            {menu && <svg style={{ transition: 'all 0.2s' }} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2.80591" y="0.454056" width="32.7364" height="2.61891" transform="rotate(45 2.80591 0.454056)" fill="white" />
              <rect x="0.954056" y="23.6022" width="32.7364" height="2.61891" transform="rotate(-45 0.954056 23.6022)" fill="white" />
            </svg>}
          </label>

        </button>
      </div>
      <motion.div
        animate={menu ? "open" : "closed"}
        variants={menuVariants}
        transition={{ type: "just" }}
        initial={{ x: "110%" }}
        onClick={()=>setMenu(false)}
        className={`tw-flex tw-flex-col tw-justify-between lg:tw-hidden tw-shadow-2xl mobile_nav tw-text-lg `}>
        <div className='tw-z-50 tw-py-3 mobile_nav_small tw-rounded'  onClick={(e)=>{
            e.stopPropagation();
          }}>
          <div className='container tw-font-light tw-flex '>
            {/* {
              !loginState.isLoggedIn && <span>
                <Link onClick={()=>setMenu(false)} to="/login"><span className='tw-btn tw-btn-ghost tw-btn-sm tw-capitalize tw-text-base'>Login</span></Link>
                <Link onClick={()=>setMenu(false)} to="/signup"><button className='tw-ml-4 tw-btn tw-btn-sm tw-capitalize btn-xs sm:btn-sm md:btn-md lg:btn-lg tw-text-base'>Sign up</button></Link>
              </span>
            }
            {
              loginState.isLoggedIn && 
              <span onClick={()=>{setMenu(false); dispatch(logout())}} ><span className='tw-ml-4 tw-btn tw-btn-sm tw-capitalize btn-xs sm:btn-sm md:btn-md lg:btn-lg tw-text-base'>Logout</span></span>
            } */}
          </div>
          {/* <div className='tw-divider tw-my-2' /> */}

          <div className='tw-flex tw-flex-col tw-text-base'>
            <NavLink style={({ isActive, isPending }) => {
            return {
                color: isActive ? "rgba(193, 44, 180, 1)" : "",
                fontWeight: isActive ? "bold" : ""
              };
            }} onClick={()=>setMenu(false)} to="/" className='tw-rounded-md tw-px-6 tw-py-3 hover:tw-text-blue-700 hover:tw-px-8 hover:tw-bg-[#7557ea47] hover:tw-font-medium tw-transition-all tw-cursor-pointer'>
              Home
            </NavLink>
            <NavLink style={({ isActive, isPending }) => {
            return {
                color: isActive ? "rgba(193, 44, 180, 1)" : "",
                fontWeight: isActive ? "bold" : ""
              };
            }} onClick={()=>setMenu(false)} to='/course' className='tw-rounded-md tw-px-6 tw-py-3 hover:tw-text-blue-700 hover:tw-px-8 hover:tw-bg-[#7557ea47] hover:tw-font-medium tw-transition-all tw-cursor-pointer'>
              Corporate Trainings
            </NavLink>
            <NavLink style={({ isActive, isPending }) => {
            return {
                color: isActive ? "rgba(193, 44, 180, 1)" : "",
                fontWeight: isActive ? "bold" : ""
              };
            }} onClick={()=>setMenu(false)} to='/verify-certificate' className='tw-rounded-md tw-px-6 tw-py-3 hover:tw-text-blue-700 hover:tw-px-8 hover:tw-bg-[#7557ea47] hover:tw-font-medium tw-transition-all tw-cursor-pointer'>
              Verify Certificate
            </NavLink>
            <NavLink style={({ isActive, isPending }) => {
            return {
                color: isActive ? "rgba(193, 44, 180, 1)" : "",
                fontWeight: isActive ? "bold" : ""
              };
            }} onClick={()=>setMenu(false)} to="/event" className='tw-rounded-md tw-px-6 tw-py-3 hover:tw-text-blue-700 hover:tw-px-8 hover:tw-bg-[#7557ea47] hover:tw-font-medium tw-transition-all tw-cursor-pointer'>
              Events
            </NavLink>
            <NavLink style={({ isActive, isPending }) => {
            return {
                color: isActive ? "rgba(193, 44, 180, 1)" : "",
                fontWeight: isActive ? "bold" : ""
              };
            }} onClick={()=>setMenu(false)} to="/blogs" className='tw-rounded-md tw-px-6 tw-py-3 hover:tw-text-blue-700 hover:tw-px-8 hover:tw-bg-[#7557ea47] hover:tw-font-medium tw-transition-all tw-cursor-pointer'>
              Blog
            </NavLink>
            <NavLink style={({ isActive, isPending }) => {
            return {
                color: isActive ? "rgba(193, 44, 180, 1)" : "",
                fontWeight: isActive ? "bold" : ""
              };
            }} onClick={()=>setMenu(false)} to="/about" className='tw-rounded-md tw-px-6 tw-py-3 hover:tw-text-blue-700 hover:tw-px-8 hover:tw-bg-[#7557ea47] hover:tw-font-medium tw-transition-all tw-cursor-pointer'>
              About Us
            </NavLink>
            <NavLink style={({ isActive, isPending }) => {
            return {
                color: isActive ? "rgba(193, 44, 180, 1)" : "",
                fontWeight: isActive ? "bold" : ""
              };
            }} onClick={()=>setMenu(false)} to="/contact" className='tw-rounded-md tw-px-6 tw-py-3 hover:tw-text-blue-700 hover:tw-px-8 hover:tw-bg-[#7557ea47] hover:tw-font-medium tw-transition-all tw-cursor-pointer'>
              Contact
            </NavLink>
          </div>
          <div className='tw-divider tw-mt-2 tw-mb-8' />
          <div className='container tw-flex tw-flex-col tw-text-base'>
            <span className='tw-font-medium'>Call Us</span>
            <a href='tel:+918093555001' className='tw-link tw-py-2 tw-font-medium tw-text-blue-700'>Mobile: +91 80935 55001</a>
            <span className='tw-py-1 tw-text-sm tw-opacity-60'>Address: Chandrasekharpur, Bhubaneswar, Odisa, India</span>
            <span className='tw-py-1 tw-text-sm tw-opacity-60'>Email: hello@finocontrol.com</span>
          </div>
        </div>
      </motion.div>
    </nav>
  )
}

function TalkToExpert() {
  return <>
    <div className='tw-flex tw-gap-3 -tw-ml-6'>
      <a href='tel:+918093555001' className='tw-btn tw-btn-sm call_btn tw-rounded'>Call Now</a>
      <a href='#requestCallbackModal' className='tw-btn tw-btn-sm call_btn tw-rounded' >Request a Call Back</a>
    </div>
  </>
}
export default Navbar